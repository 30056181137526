import { Base64 } from "js-base64";
import $ from 'jquery';

/**
 * Implicit dependency on:
 * - bootstrap3-typeahead.min.js
 */
$(() => {
  $("body.mf-home .searchstores, body.mf-contact-us .searchstores").on(
    "click",
    function(e) {
      e.preventDefault();
      var $storefinderform = $(this).parents("form");

      var valid = validateHomePageStoreFinderForm($storefinderform);
      if (valid) {
        var params = {
          address: $("input[name='address']", $storefinderform).val(),
          businessUnitIdentifiers: getSelectedStoreTypes($storefinderform)
        };
        if ($storefinderform.is("#mobileStorefinderSearch")) {
          params["limit"] = 4;
        }
        updateBrowserUrl($.param(params, true));
      }
      return false;
    }
  );

  $("body.mf-home .use-location, body.mf-contact-us .use-location").on(
    "click",
    function(e) {
      e.preventDefault();
      searchViaUserLocation();
      return false;
    }
  );

  function validateHomePageStoreFinderForm(form) {
    if (
      $("input[name='address']", form).val() == "" ||
      $("input[name='address']", form).val().length < 3
    ) {
      if (form.attr("id") == "mobileStorefinderSearch") {
        alert("Please enter a valid postcode.");
      } else {
        alert("Please enter a valid suburb and state or postcode.");
      }

      return;
    }
    return true;
  }

  function getSelectedStoreTypes(form) {
    var types = new Array();
    var allTypes = new Array();

    if ($("select[name='homepageStoreType']", form).length > 0) {
      $("select[name='homepageStoreType'] > option", form).each(function() {
        if ($(this).prop("selected") && $(this).val() !== "") {
          types.push($(this).val());
        }

        if ($(this).val() !== "") {
          allTypes.push($(this).val());
        }
      });

      if (types.length == 0) {
        types = allTypes;
      }
    } else if (
      $("input[name='businessUnitIdentifiers'][type='checkbox']", form).length >
      0
    ) {
      $("input[name='businessUnitIdentifiers'][type='checkbox']", form).each(
        function() {
          if ($(this).prop("checked")) {
            types.push($(this).val());
          }

          allTypes.push($(this).val());
        }
      );

      if (types.length == 0) {
        types = allTypes;
      }
    } else {
      types.push($("input[name='businessUnitIdentifiers']", form).val());
    }

    return types;
  }

  function updateBrowserUrl(params) {
    params = Base64.encode(params);

    if (window.location.port !== 80 && window.location.port !== "") {
      window.location =
        window.location.protocol +
        "//" +
        window.location.hostname +
        ":" +
        window.location.port +
        "/store-finder#" +
        params;
    } else {
      window.location =
        window.location.protocol +
        "//" +
        window.location.hostname +
        "/store-finder#" +
        params;
    }
  }

  function searchViaUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    } else {
      alert("geolocation not supported");
    }
  }

  function geoSuccess(position) {
    var form = $("form.storefinderform");

    if ($("#mobileStorefinderSearch").length > 0) {
      form = $("#mobileStorefinderSearch");
    }

    var params = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      limit: 4,
      businessUnitIdentifiers: getSelectedStoreTypes(form)
    };
    updateBrowserUrl($.param(params, true));
  }

  function geoError(err) {
    var error = err.code;
    var msg;
    switch (error) {
      case 0:
        var strErrorCode = error.code.toString();
        msg = "Unknown Error: " + strErrorCode;
        break;
      case 1:
        msg = "User Permission Denied";
        break;
      case 2:
        msg = "Position Unavailable";
        break;
      case 3:
        msg = "Timeout";
        break;
    }
    alert(msg);
  }

    const $storefinderForm = $('form.storefinderform input[name="address"]');
    if ($storefinderForm.length > 0) {
        $storefinderForm.typeahead({
          source: function(query, process) {
            return $.get(
              "/trsonline/stores/public/suburbs/search",
              { query: query },
              function(data) {
                var newData = [];

                $.each(data, function() {
                    newData.push(
                        this.suburb + " " + this.codeNumber + ", " + this.state
                    );
                });

                return process(newData);
              }
            );
          },
          minLength: 3
        });
    }

  $('input[name="address"]').on("input", function() {
    var form = $(this).parents("form:first");
    var length = 4;

    if (form.hasClass("storefinderform")) {
      length = 3;
    }

    if ($(this).val().length >= length) {
      form.find(".searchstores").prop("disabled", false);
    } else {
      form.find(".searchstores").prop("disabled", true);
    }
  });
});
