import "./_hero-banner.scss";
import Swiper from "swiper/dist/js/swiper.min.js";

const init = function () {

    new Swiper ('#hero-slider', {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.swiper-next-js',
            prevEl: '.swiper-prev-js',
        },
        breakpoints : {
        }
    });


};

export default {
    init
};
